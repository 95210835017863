<template>
  <div class="vista-escritorio">
    <Cabecera />
    <Cuerpo>
      <div class="votos">
        <div class="votos-cabecera">
          <div class="titulos">
            <h2>
              {{ eleccionLeyenda }}
              -
              <span>
                <strong>
                  Secciones por
                  <b v-if="eleccionLetra === 'A'">
                    Municipio
                  </b>
                  <b v-else>
                    Distrito
                  </b>
                </strong>
              </span>
            </h2>

            <h4>
              <strong>
                <router-link
                  :to="`/${this.$route.params.eleccion}/ENT/VPC`"
                  class="estado-volver"
                >
                  Baja California Sur
                </router-link>
              </strong>
              /
              <router-link
                :to="`/${this.$route.params.eleccion}/DET/VPC/${distrito}`"
                class="estado-volver"
              >
                <span v-if="this.$route.params.eleccion != 'A'"
                  >Distrito {{ distrito }}. {{ distrito_nombre }}</span
                >
                <span v-else>
                  Municipio {{ this.distrito }}.
                  {{ ayuntamiento.municipio_descripcion }}
                </span>
              </router-link>
              <span> / Secciones </span>
            </h4>

            <p>
              El total de votos calculado y porcentaje que se muestran, se refieren a los votos
              asentados en las actas PREP hasta el momento. <br />
              Por presentación, los decimales de los porcentajes muestran sólo cuatro dígitos. No
              obstante, al considerar todos los decimales, suman 100%.
            </p>

            <h3>Votos en Actas Contabilizadas</h3>
          </div>
        </div>

        <slider-votos :votos="votosXCandidatoDistritoTarjeta.datos_candidaturas" />

        <div class="titulos">
          <div class="row px-3">
            <div class="col-8 mb-3 resumen-votacion">
              <h4 class="mb-2">
                Resumen de la votación
              </h4>
              <div class="grilla-suma">
                <div class="acumulados text-center">
                  <p class="font-weight-bold">
                    Votos acumulados
                    <span id="tooltip">
                      <font-awesome-icon
                        icon="info-circle"
                        class="fa-sm fa-info"
                      />
                      <span class="tooltiptext">
                        Es la sumatoria de los votos obtenidos <br />
                        por Partido Político, Coalición <br />
                        o Candidatura Independiente.
                      </span>
                    </span>
                    <br />
                    {{ resumenDistrito.total_votos_acumulados_numero }} <br />
                    <span class="porcentaje">
                      {{ resumenDistrito.total_votos_acumulados_porcentaje }}%
                    </span>
                  </p>
                </div>
                <div class="icono-suma">
                  <font-awesome-icon icon="plus" class="fa-2x" />
                </div>
                <div class="no-registradas text-center">
                  <p class="font-weight-bold">
                    Candidaturas no registradas <br />
                    {{
                      resumenDistrito.total_votos_candidatura_noregistrada_numero
                    }}
                    <br />
                    <span class="porcentaje">
                      {{
                        resumenDistrito.total_votos_candidatura_noregistrada_porcentaje
                      }}%
                    </span>
                  </p>
                </div>
                <div class="icono-suma">
                  <font-awesome-icon icon="plus" class="fa-2x" />
                </div>
                <div class="nulos text-center">
                  <p class="font-weight-bold">
                    Nulos <br />
                    {{ resumenDistrito.total_votos_nulos_numero }} <br />
                    <span class="porcentaje">
                      {{ resumenDistrito.total_votos_nulos_porcentaje }}%
                    </span>
                  </p>
                </div>
                <div class="icono-suma">
                  <font-awesome-icon icon="equals" class="fa-2x" />
                </div>
                <div class="total text-right">
                  <p class="font-weight-bold">
                    Total de votos <br />
                    <big>{{ resumenDistrito.total_votos_general_numero }}</big>
                    <br />
                    <span class="porcentaje">
                      {{ resumenDistrito.total_votos_general_porcentaje }}%
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="votos">
        <div class="votos-cabecera">
          <div class="titulos">
            <div class="table-responsive my-4">
              <table class="table table-striped">
                <thead>
                  <tr class="text-center">
                    <th>
                      Secciones
                    </th>
                    <th
                      v-for="(encabezado,
                      index) in votosXSeccionNivelSeccion.datos_nombres_columnas.slice(
                        1
                      )"
                      :key="index"
                    >
                      <img
                        v-if="encabezado.candidatura_logo != 'sin logo'"
                        :src="encabezado.candidatura_logo"
                        :alt="encabezado.candidatura_nombre"
                      />
                      <span v-else>{{ encabezado.candidatura_nombre }}</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(seccion,
                    index) in votosXSeccionNivelSeccion.datos_secciones"
                    :key="index"
                    class="text-center"
                  >
                    <td>
                      <router-link
                        :to="
                          `/${eleccionLetra}/Secciones/${distrito}/${seccion.seccion_id}`
                        "
                        class="font-weight-bold"
                      >
                        <u> Sección {{ seccion.seccion_id }} </u>
                      </router-link>
                    </td>
                    <td
                      v-for="(candidato, key) in seccion.datos_votos"
                      :key="key"
                    >
                      {{ candidato.candidatura_votos_cantidad }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div class="votos">
        <div class="votos-cabecera">
          <div class="titulos">
            <h3>
              Estadística
              <span v-if="eleccionLeyenda !== 'Ayuntamientos'"
                >Distrito {{ distrito }}. {{ distrito_nombre }}</span
              >
              <span v-else
                >Municipio {{ distrito }}.
                {{ ayuntamiento.municipio_descripcion }}</span
              >
            </h3>

            <EstadisticasInternas :detalle="estadisticas" />
          </div>
        </div>
      </div>
      <div class="text-center">
        <p>
          Los resultados presentados tienen un carácter informativo y no son
          definitivos, por tanto, no tienen efectos jurídicos. <br />
          Con base en la Ley Federal del Derecho de Autor queda prohíbida
          cualquier modificación al diseño de este sitio. <br />
          La modificación y/o destrucción de la información y/o contenido total
          o parcial de este sitio, es delito federal de acuerdo al Código Penal
          Federal.
        </p>
      </div>
    </Cuerpo>
    <Compartir />
    <PieDePagina />
  </div>
</template>

<script>
import Cabecera from '@/components/Escritorio/componentes/Cabecera.vue';
import Cuerpo from '@/components/Escritorio/componentes/Cuerpo.vue';
import Compartir from '@/components/Compartir.vue';
import EstadisticasInternas from '@/components/Escritorio/componentes/EstadisticasInternas.vue';
import PieDePagina from '../componentes/PieDePagina.vue';
import SliderVotos from '../componentes/SliderVotos.vue';

export default {
  name: 'SeccionesEscritorio',
  components: {
    Cabecera,
    Cuerpo,
    EstadisticasInternas,
    Compartir,
    PieDePagina,
    SliderVotos,
  },
  computed: {
    distrito_nombre() {
      return this.$store.state.elementosGenerales.datos_distritos.find(
        (d) => parseInt(d.id, 10) === parseInt(this.distrito, 10),
      ).descripcion;
    },
    eleccionLeyenda() {
      if (this.$route.params.eleccion === 'G') {
        return 'Gubernatura';
      } if (this.$route.params.eleccion === 'D') {
        return 'Diputaciones';
      } if (this.$route.params.eleccion === 'A') {
        return 'Ayuntamientos';
      }

      return '';
    },
    ayuntamiento() {
      return this.$store.state.elementosGenerales.datos_municipios[
        this.distrito - 1
      ];
    },
    eleccionLetra() {
      return this.$route.params.eleccion;
    },
    distrito() {
      return this.$route.params.distrito;
    },
    detalle() {
      /**
       * Aqui debemos de llamar al distrito correspondiente
       * acorde a las variables "Entidad" y "Distrito",
       * por lo pronto quedará estático con lo que tenemos en el JSON que nos dio el INE
       */

      return this.$store.state.distritos.uno;
    },
    estadisticas() {
      if (this.$route.params.eleccion === 'G') {
        return this.$store.state.gEstadisticasNivelDistrito.distritos[
          this.$route.params.distrito - 1
        ];
      } if (this.$route.params.eleccion === 'D') {
        return this.$store.state.dEstadisticasNivelDistrito.distritos[
          this.$route.params.distrito - 1
        ];
      } if (this.$route.params.eleccion === 'A') {
        return this.$store.state.aEstadisticasNivelDistrito.municipios[
          this.$route.params.distrito - 1
        ];
      }

      return {};
    },
    votosXCandidatoDistritoTarjeta() {
      if (this.$route.params.eleccion === 'G') {
        console.log(this.$store.state.gVotosXCandidatoCombinacionesDistritoTarjeta);
        return this.$store.state.gVotosXCandidatoCombinacionesDistritoTarjeta.distritos[
          this.distrito - 1
        ];
      } if (this.$route.params.eleccion === 'D') {
        return this.$store.state.dVotosXCandidatoCombinacionesDistritoTarjeta.distritos[
          this.distrito - 1
        ];
      } if (this.$route.params.eleccion === 'A') {
        return this.$store.state.aVotosXCandidatoCombinacionesDistritoTarjeta.municipios[
          this.distrito - 1
        ];
      }

      return {};
    },
    resumenDistrito() {
      if (this.$route.params.eleccion === 'G') {
        return this.$store.state.gResumenNivelDistrito.distritos[
          this.distrito - 1
        ];
      } if (this.$route.params.eleccion === 'D') {
        return this.$store.state.dResumenNivelDistrito.distritos[
          this.distrito - 1
        ];
      } if (this.$route.params.eleccion === 'A') {
        return this.$store.state.aResumenNivelDistrito.distritos[
          this.distrito - 1
        ];
      }

      return {};
    },
    votosXSeccionNivelSeccion() {
      if (this.$route.params.eleccion === 'G') {
        return this.$store.state.gVotosXSeccionNivelSeccion.datos_distritos[
          this.distrito - 1
        ];
      } if (this.$route.params.eleccion === 'D') {
        return this.$store.state.dVotosXSeccionNivelSeccion.datos_distritos[
          this.distrito - 1
        ];
      } if (this.$route.params.eleccion === 'A') {
        return this.$store.state.aVotosXSeccionNivelSeccion.datos_municipios[
          this.distrito - 1
        ];
      }

      return {};
    },
  },
  methods: {
    seleccionarCandidato(index) {
      // console.log(this.$store.state.distritos.uno.votosCandidatura[index])
      this.$store.state.distritos.uno.votosCandidatura[
        index
      ].seleccionado = !this.$store.state.distritos.uno.votosCandidatura[index]
        .seleccionado;
    },
    onCheckboxChange() {
      this.$forceUpdate();
      this.random = Math.random();
    },
  },
};
</script>

<style lang="scss" scoped>
.resumen-voto-candidatura {
  padding: 3px;
  border: 1px solid #181a2f;
  //height: 25vh;
  max-width: 150px;
  .grilla-datos-candidatura {
    margin: 10px;
    .foto > svg {
      max-width: 50px;
      margin: 0 auto;
      display: block;
      margin-bottom: 10px;
    }
  }
  .grilla-resumen {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: flex-end;
    gap: 15px;
    margin-top: 10px;
  }
}

.ganador {
  border-color: #d2127e;
  border-width: 3px;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;

  .total {
    p > span {
      color: #d2127e;
    }
  }
}

.boton-seleccionar {
  width: 100%;
  display: block;
  text-align: center;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  background-color: #181a2f;
  border-color: transparent;

  label {
    border: none;
    width: 100%;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }
}

.seleccionado {
  background-color: lighten(#181a2f, 10%);
}

.resumen-votacion {
  background-color: #f4f4f4;
  padding: 10px;
  border-radius: 15px;

  .grilla-suma {
    display: grid;
    grid-template-columns: 1fr 20px 1fr 20px 1fr 20px 1fr;
    /* max-width: 900px; */
    align-items: center;
    gap: 30px;

    .acumulados,
    .nulos,
    .no-registradas,
    .total {
      p {
        color: #34344e;
      }
    }

    .total {
      big {
        font-size: 2rem;
      }
      big,
      .porcentaje {
        color: #d2127e;
      }
    }
  }
}

.table-responsive {
  thead {
    tr {
      background-color: #181a2f;
      color: #fff;

      th {
        vertical-align: middle;
        img {
          max-height: 35px;
        }
      }
    }
  }
  tbody {
    tr {
      td {
        vertical-align: middle;
        img {
          max-width: 50px;
        }
      }
      .votos {
        text-align: center;
        p {
          font-size: 2rem !important;
          font-weight: bold !important;
        }
      }
    }
  }
}

.boton-verdetalle {
  background-color: #181a2f;
  border-color: transparent;
}

.rowActas {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;

  .elemento {
    /* max-width: 140px; */
    display: inline-block;
    margin-right: 30px;
    border: 1px solid black;
    padding: 0;
    img {
      max-width: 40px;
      padding-bottom: 20px;
      padding-top: 20px;
    }

    .grilla-partidos {
      display: grid;
      background-color: #f4f4f4;
      grid-template-columns: 1fr 1fr;
      gap: 30px;
      padding: 10px;
      align-items: flex-end;
    }
  }

  /* @media (min-width: 1200px) {
    max-width: 1000px;
  }
  @media (min-width: 1400px) {
    max-width: 1200px;
  } */
}

.votos {
  overflow: auto;
}
</style>
