<template>
  <div>
    <div v-if="cargaTerminada">
      <SeccionesEscritorio v-if="anchoPantalla >= 992" />
    </div>
    <div class="container-fluid" v-else>
      <div class="row">
        <div class="col-12">
          <div class="cargando">
            <div>
              <Spinner />
              <p class="text-center my-5">
                Cargando contenido...
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SeccionesEscritorio from '@/components/Escritorio/vistas/Secciones.vue';
import Spinner from '@/components/Spinner.vue';

export default {
  name: 'Secciones',
  components: {
    SeccionesEscritorio,
    Spinner,
  },
  computed: {
    anchoPantalla() {
      return this.$store.state.anchoPantalla;
    },
    cargaTerminada() {
      return this.$store.state.cargaTerminada;
    },
  },
  watch: {
    anchoPantalla: {
      handler(val) {
        if (val < 992) {
          const { eleccion, distrito } = this.$route.params;
          this.$router.push(
            `/${eleccion}/DET/VPC/${distrito}`,
          );
        }
      },
    },
  },
  metaInfo: {
    title: 'IEEBCS - PREP 2021 - Secciones',
  },
};
</script>

<style lang="scss" scoped>
.cargando {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  height: 100vh;

  .sk-chase {
    margin: 0 auto;
    text-align: center;
  }
}
</style>
