<template>
  <div class="grilla">
    <div class="">
      <h5 class="font-weight-bold mb-3">
        <u>Actas del {{ eleccion }}</u>
      </h5>
    </div>
    <div class="">
      <h5 class="font-weight-bold mb-3">
        Lista Nominal de Actas Contabilizadas
      </h5>
    </div>
    <div class="">
      <h5 class="font-weight-bold mb-3">
        Participación ciudadana con base en la Lista Nominal de las Actas PREP Contabilizadas
      </h5>
    </div>

    <div class="">
      <div class="grilla-estadisticas mb-4">
        <div class="esperadas">
          <ProgresoVertical
            class="mb-3"
            :avance="calcularAvance(detalle.actas_esperadas_porcentaje)"
            :color="colorEsperadas"
          />
        </div>
        <div class="capturadas">
          <ProgresoVertical
            class="mb-3"
            :avance="calcularAvance(detalle.actas_capturadas_porcentaje)"
            :color="colorCapturadas"
          />
        </div>
        <div class="contabilizadas">
          <ProgresoVertical
            class="mb-3"
            :avance="calcularAvance(detalle.actas_contabilizadas_porcentaje)"
            :color="colorContabilizadas"
          />
        </div>
        <div>
          <p>
            {{ detalle.actas_esperadas_numero }} <br />
            {{ detalle.actas_esperadas_porcentaje }}% <br />
            Esperadas
          </p>
        </div>
        <div>
          <p>
            {{ detalle.actas_capturadas_numero }} <br />
            {{ detalle.actas_capturadas_porcentaje }}% <br />
            Capturadas
          </p>
        </div>
        <div>
          <p>
            {{ detalle.actas_contabilizadas_numero}} <br />
            {{ detalle.actas_contabilizadas_porcentaje }}% <br />
            Contabilizadas
          </p>
        </div>
      </div>
    </div>
    <div class="">
      <div class="listaNominal mb-5">
        <h1 class="text-center font-weight-bold mb-3" style="font-size:42px">
          {{ detalle.valor_lista_nominal_actas_contabilizadas_numero }}
        </h1>
        <p class="text-center small mb-4">
          Lista Nominal de Actas Contabilizadas
        </p>
      </div>
    </div>
    <div class="">
      <p>
        El porcentaje de Participación Ciudadana se obtiene al multiplicar los
        Votos por 100 y dividir el resultado de esta operación entre el número de
        personas registrado en la Lista Nominal de Actas Contabilizadas.
      </p>

      <p class="text-center font-weight-bold mb-2">
        Porcentaje
      </p>
      <h1 class="text-center font-weight-bold primario display-4">
        {{  detalle.participacion_ciudadana_porcentaje }}%
      </h1>
      <p class="text-center mb-0">
        Porcentaje calculado <b>sin considerar</b> la votación<br />
        en <b>Casillas Especiales.</b>
      </p>
      <p class="text-center mb-2">
        (Total de votos de las Actas Contabilizadas / Lista Nominal)
        x 100 = % de Participación Ciudadana.
      </p>
    </div>
  </div>
</template>

<script>
import ProgresoVertical from '@/components/Movil/componentes/ProgresoVertical.vue';

export default {
  name: 'EstadisticasInternas',
  components: {
    ProgresoVertical,
  },
  props: {
    detalle: Object,
  },
  computed: {
    colorEsperadas() {
      if (this.$store.state.esOscuro) {
        return 'fd2a71';
      }
      return 'd2127e';
    },
    colorCapturadas() {
      if (this.$store.state.esOscuro) {
        return 'fe9db0';
      }
      return '79144c';
    },
    colorContabilizadas() {
      if (this.$store.state.esOscuro) {
        return 'fec5cd';
      }
      return 'efb5d3';
    },
    colorUrbano() {
      if (this.$store.state.esOscuro) {
        return 'F1B1D7';
      }
      return 'd2127e';
    },
    eleccion() {
      return this.$route.params.eleccion == 'D' || this.$route.params.eleccion == 'G' ? 'Distrito' : 'Municipio';
    },
  },
  methods: {
    calcularAvance(avance) {
      return parseFloat(avance);
    },
  },
};
</script>

<style lang="scss" scoped>
.grilla {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  align-items: center;
  max-width: 1000px;
  margin: 0 auto;
}
.grilla-estadisticas {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;

  p {
    font-weight: 500;
    text-align: center;
    font-size: 14px;
  }

  div {
    margin: 0 auto;
  }
}
</style>
